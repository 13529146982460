import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Button, Card, CardBody, CardImg, Col, Container, Input, Row } from 'reactstrap';
import { exercise } from 'helpers/api_url';
import { toast } from 'react-toastify';
import './Exercise.css';
import image from '../../../assets/images/Execise/ex2.jpeg';
import { useNavigate } from 'react-router-dom';
import { ExerciseLike } from './ExerciseLike';

export const ExerciseData = () => {
    const [tabledata, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        exercise.list()
            .then(res => setTableData(res.data.exercise))
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
    }, []);
    const filteredData = tabledata.filter(item => 
        item.exercise_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        JSON.parse(item.area_of_body_part).includes(searchTerm.toLowerCase())
    );
    const handleExerciseView = exercise_id =>  navigate('/view-excercise/'+exercise_id);
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">
                <Container className="mt-5">
                    <h1 className="text-center mb-4">Fittestwarrior Exercises</h1>
                    
                    <div className="d-flex justify-content-center my-4 __search-bar-wrapper">
                        <Input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search for exercises eg: name, category, body part..."
                            className="__search-input"
                        />
                    </div>
                    <Row>
                        {
                            filteredData.length ? filteredData.map((item, idx) => (
                                <Col md="3" sm="6" className="mb-4" key={idx}>
                                    <Card className="__exercise-card shadow" >
                                        <div className="__image-wrapper position-relative">
                                           <ExerciseLike />
                                            <CardImg
                                                top
                                                src={image} 
                                                alt="Exercise"
                                                className="__exercise-image"
                                            />
                                        </div>
                                        <CardBody className="text-center" style={{height:'200px'}}>
                                            <h6 className="__exercise-category">{item.exercise_name}</h6>
                                            <p className="__exercise-date">{JSON.parse(item.area_of_body_part)}</p>
                                            <h5 className="__exercise-title">{JSON.parse(item.gravity)}</h5>
                                            <Button color="dark" onClick={()=>handleExerciseView(item.id)} className="__show-button">Show Details</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )) : (
                                <div className="text-center">No exercises found.</div>
                            )
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
