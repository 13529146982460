import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Button, Card, CardBody, CardImg, Col, Container, Input, Row } from 'reactstrap';
import { Diet} from 'helpers/api_url';
import { toast } from 'react-toastify';
import image from '../../../assets/images/Diet/diet-plans-1.jpg';
import { useNavigate } from 'react-router-dom';
export const DietPage = () => {
    const [tabledata, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        Diet.list()
            .then(res => setTableData(res.data.food))
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
         }, []);
    const filteredData = tabledata.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()) );
    const handleDietView = diet_id =>  navigate('/view-diet/'+diet_id);
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5" >
                <Container className="mt-5">
                    <h1 className="text-center mb-4">Fittestwarrior Diet</h1>
                    <div className="d-flex justify-content-center my-4 __search-bar-wrapper">
                        <Input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search for Diet eg: name"
                            className="__search-input"
                        />
                    </div>
                    <Row>
                        {
                            filteredData.length ? filteredData.map((item, idx) => (
                                <Col md="3" sm="6" className="mb-4" key={idx}>
                                    <Card className="__exercise-card shadow" >
                                        <div className="__image-wrapper position-relative">
                                            <CardImg
                                                top
                                                src={image} 
                                                alt="Exercise"
                                                className="__exercise-image"
                                            />
                                        </div>
                                        <CardBody className="text-center" style={{height:'100px'}}>
                                            <h6 className="__exercise-category">{item.name}</h6>
                                            <Button color="dark" onClick={()=>handleDietView(item.id)} className="__show-button">Show Details</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )) : (
                                <div className="text-center">No Diet found.</div>
                            )
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
