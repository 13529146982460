import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../pages/Authentication/Login";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Demo from "../pages/Admin/Demo/index";

import Calculatortab from "pages/Public/Calculators/index"

import Professional from "pages/Admin/Consultancy/Professional/index";
import Appointment from "pages/Admin/Consultancy/Appointment/index";
import CreateDiet from "pages/Admin/Diet/create/index";
import DietPlan from "pages/Admin/Diet/presetPlan/index";
import Plan from "pages/Admin/Trainer/Plan/index";
import Analyse from "pages/Admin/Trainer/Analyse_Test";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import Register from "pages/Authentication/Register";
import Locked from "components/Common/Locked";
import { Defaultdashboard } from "pages/Dashboard/Defaultdashboard";
import Profile from "pages/DefaultPage/profile";
import { Participator } from "pages/Admin/Users/participator";
import { Community } from "pages/Admin/Users/Community";
import { Client } from "pages/Admin/Users/Client";
import { Brands } from "pages/Admin/Users/Brands";
import { Supporting } from "pages/Admin/Users/Supporting";
import { Proffessional } from "pages/Admin/Users/Proffessional";
import { Event } from "pages/Public/Game/Event";
import Games from "pages/Admin/Games"
import { ParticipatorRequest } from "pages/Admin/Community/participatorRequest";
import Assessment from "pages/Admin/Community/ParticipatorAsses/index";
import BodyStatic from "pages/Admin/BodyStatic/BodyStatic";
import UrgentIssues from "pages/Admin/Community/UrgentIssues";
import AddEx from "pages/Admin/Excersises/AddEx";
import ExerciseCategory from "pages/Admin/Excersises/ExerciseCategory";
import ExerciseSchedule from "pages/Admin/Excersises/ExerciseSchedule";
import Exercise from "pages/Admin/Excersises/Exercise";
import Scheduler from "pages/Admin/Excersises/Scheduler/Scheduler";
import { Timetable } from "pages/Admin/TimeTable";
import LandingPage from "pages/LandingPage";
import { ShowBlog } from "pages/Public/Blog/ShowBlog";
import { ViewJob } from "pages/Public/Job";
import { JobViewDetail } from "pages/Public/Job/JobViewDetail";
import TimeTableDownloads from "pages/Admin/TimeTable/TimeTableDownloads";
import DayRoutine from "pages/Admin/TimeTable/DayRoutine";
import { ExcersiseView } from "pages/Public/Execise";
import { DietView } from "pages/Public/Diet";
import Myappointments from "pages/Admin/Consultancy/Appointment/Myappointments";
import { SingleExcercise } from "pages/Public/Execise/singleExcercise";
import ProfViewList from "pages/Admin/Consultancy/Professional/ProfViewList";
import ProfProfile from "pages/Admin/Consultancy/Professional/ProfProfile";
import { CreateCompany } from "pages/Admin/Company/CreateCompany";
import CompanyKyc from "pages/Admin/Company";
import { CompanyPro } from "pages/Admin/Company/CompanyPro";
import { LandingSetting } from "pages/Admin/Setting";
import { AboutUsPage } from "pages/LandingPage/AboutUsPage";
import { Team } from "pages/LandingPage/OurTeam/Team";
import Mission from "pages/LandingPage/Mission/Mission";
import Scholarship from "pages/LandingPage/Scholarship/Scholarship";
import Sponsorship from "pages/LandingPage/Sponsorship/Sponsorship";
import Promotion from "pages/LandingPage/Scholarship/Promotion";
import { Partner } from "pages/LandingPage/Partner_barter/Partner";
import { ExerciseData } from "pages/LandingPage/ExercisePage/ExerciseData";
import { ExerciseDetails } from "pages/LandingPage/ExercisePage/ExerciseDetails";
import { DietPage } from "pages/LandingPage/DietPage/DietPage";
import { DietDetails } from "pages/LandingPage/DietPage/DietDetails";
import ViewTimetable from "pages/LandingPage/CarouselTypes/TimeTable";
import CalculatorPage from "pages/LandingPage/FitnessCalculator/CalculatorPage";
import SelfAssessment from "pages/LandingPage/SelfAssessement/SelfAssessment";
import FitnessTest from "pages/LandingPage/FitnessTest/FitnessTest";
import ProgramTrainer from "pages/LandingPage/programTrainer/ProgramTrainer";
import ConsultancyPage from "pages/LandingPage/ConsultancyPage/ConsultancyPage";
import CommunityNetwork from "pages/LandingPage/CommunityNetwork/CommunityNetwork";

const usertype = [
  { user: 'participator', type: false },
  { user: 'admin', type: true },
]
const allusers = ["admin", "participator", "community", "brand", "client", "supporting_staff", "Proffessional", "frenchisers",]
const authProtectedRoutes = [
  { path: "/timetable", exact: true, component: <Timetable />, users: ['community', 'participator', 'vendor', 'brand', 'Proffessional', 'volunteer', 'admin'] },
  { path: "/downloads/timetable",exact: true, component:<TimeTableDownloads />,users:allusers},
  { path: "/day-routine",exact: true, component:<DayRoutine />,users:allusers},
  { path: "/lock", exact: true, component: <Locked />, users: allusers },
  { path: "/profile", exact: true, component: <Profile />, users: allusers },
  
  { type: 'menu', label: 'Dashboard', path: "/dashboard", component: <Dashboard />, icon: 'bx bx-home-circle', users: ['admin','Proffessional','community','Participator'] },
  
  {type: 'menu', label: 'Jobs', path:'https://jobs.fittestwarrior.com/', icon: 'bx bx-briefcase-alt', users: allusers},
  {type: 'menu', label: 'Blogs',path: 'https://blogs.fittestwarrior.com/', icon: 'bx bxs-detail', users: allusers},
  {type: 'menu', label: 'Events',path: 'https://events.fittestwarrior.com/', icon: 'bx bx-run', users: allusers},
  // { type: 'menu', label: 'Community', path: '/community', icon: 'bx bx-briefcase-alt-2', component: <Community />, users: ['admin'], },
  { path: "/prof-profile/:id", exact: true, component: <ProfProfile />, users: allusers },

  {type: 'menu', label: 'Consultancy', path:'/consultancy', icon: 'bx bx-briefcase-alt-2', users: ['community', 'participator', 'Proffessional', 'volunteer', 'admin'], children: [
      { label: 'Apply for Appointment', path: '/consultancy/appointment', component: <Appointment />, users: [ 'participator','Proffessional'] },
      { label: 'Appointments', path: '/consultancy/my-appointments', component: <Myappointments />, users: ['Proffessional','admin'] },
      { label: 'Edit Profile', path: '/consultancy/professional', component: <Professional />, users: ['community',  'Proffessional', 'volunteer', 'admin'] },
      { label: 'Proffessionals', path: '/consultancy/professional-list', component: <ProfViewList />, users: ['community',  'Proffessional', 'volunteer', 'admin'] },
    ]
  },
  {type: 'menu', label: 'Fitness and Health', path:'/fitness-health', icon: 'bx bx-cycling', users: allusers, children: [
      { label: 'Calculator', path: '/fitness-health/calculator', component: <Calculatortab />, users: allusers },
      { label: 'Assessment', path: '/fitness-health/assessment', component: <Assessment />, users: ['participator','visitor','Proffessional','volunteer', 'admin'] },
      { label: 'Body Static', path: '/fitness-health/body-static', component: <BodyStatic />, users: ['participator','visitor','Proffessional','volunteer', 'admin'] },
      { label: 'Urgent Issue', path: '/fitness-health/urgentissue', component: <UrgentIssues />, users: ['participator','visitor','Proffessional','volunteer', 'admin'] },
      { label: 'Food and Diet', path: '/fitness-health/createDiet', component: <CreateDiet />, users: allusers },
      { label: 'Exercises', path: '/fitness-health/exercise-all', component: <Exercise />, users: allusers },
      {label:'Time Table',path:'/fitness-health/time-table',component: <Timetable />,users:allusers},
      { label: 'Fitness Training', path: '/fitness-health/presetDiet', component: <DietPlan />, users: ['community', 'brand', 'admin'] },
      { label: 'Fitness Test', path: '/fitness-health/presetDiet', component: <DietPlan />, users: ['community', 'brand', 'admin'] },
    ]
  },
  // { type:'menu',label:'Trainer' , icon:'bx bx-dumbbell',users:['admin','community'],children:[
  //   {label:'Analyse Test',path:'/createplan',component: <Analyse  />,users:['admin','community']},
  //   {label:'Plan/Schedule',path:'/planschedule',component:<Plan /> },
  // ]},
  // { type: "menu",
  //   label: "Exercise",icon: "bx bx-cycling",users: allusers, children: [
  //     {label: "Add Exercise",path: "/add-ex", component: <AddEx />},
  //     {label: "Exercise List",path: "/add-ex", component: <AddEx />},
  //     {label: "Exercise Category",path: "/ex-Category",component: <ExerciseCategory />},
  //     {label: "Schedule",path: "/ex-shedule",component: <ExerciseSchedule />},
  //     {label: "Scheduler",path: "/scheduler",component: <Scheduler />,users: allusers,},
  //   ],},
  // { type:'menu',label:'Calculators',icon:'bx bx-calculator',users:allusers,children: [
  //   {label: "BMI/BMR/BAC",path: "/calculator",component: <Calculatortab />,},
  // ]},
  {
    type: 'menu', label: 'Participator Management', icon: 'bx bx-calculator', users: ['community',  'vendor', 'brand', 'Proffessional', 'volunteer'], children: [
      { label: 'participator', path: '/participator', component: <Participator />, users: ['community', 'participator', 'vendor', 'brand', 'Proffessional', 'volunteer', 'admin'] }, // dont change | only participator management 
      { label: 'participator Requests', path: '/participator/Request', component: <ParticipatorRequest />, users: ['admin','community'] },
      // { label: 'Assessment', path: '/assessment', component: <Assessment />, users: ['community', 'participator', 'vendor', 'brand', 'Proffessional', 'volunteer', 'admin'] },
      // { label: 'Body Static', path: '/body-static', component: <BodyStatic />, users: ['community', 'participator', 'vendor', 'brand', 'Proffessional', 'volunteer', 'admin'] },
      // { label: 'Urgent Issue', path: '/urgentissue', component: <UrgentIssues />, users: ['community', 'participator', 'vendor', 'brand', 'Proffessional', 'volunteer', 'admin'] },
    ]
  },
  {
    type: 'setting', label: 'Users', icon: 'bx bx-user-plus', users: ['admin'], children: [
      { label: 'participator', path: '/participator', component: <Participator />, users: ['admin'] }, // change to user managemet component
      { label: 'Community', path: '/community', component: <Community />, users: ['admin'] },
      { label: 'Client', path: '/client', component: <Client />, users: ['admin'] },
      { label: 'Brand', path: '/brand', component: <Brands />, users: ['admin'] },
      { label: 'Supporting', path: '/supporting', component: <Supporting />, users: ['admin'] },
      { label: 'Proffessional', path: '/proffessional', component: <Proffessional />, users: ['admin'] },
    ]
  },

  { path: "/setting", exact: true, component: <LandingSetting />, users: ['admin'] },
  
  {type: 'menu', label: 'Company',path: '/companylist', component:<CompanyKyc  />, icon: 'bx bxs-detail', users: allusers, },
  { path: "/company-profile/:id", exact: true, component: <CompanyPro />, users: allusers },
  // {type: 'menu', label: 'kyc',path: '/kyc', component:<CreateKyc  />, icon: 'bx bxs-detail', users: allusers, },
  {path: '/create-company', component:<CreateCompany  />, icon: '', users: allusers, },
  { path: "/", exact: true, component: <Navigate to="/dashboard" />, },
];
const publicRoutes = [
  { path: "/", component: <LandingPage /> },
  { path: "/aboutus", component: <AboutUsPage /> },
  { path: "/teams", component: <Team /> },
  { path: "/mission", component: <Mission /> },
  { path: "/scholarship", component: <Scholarship /> },
  { path: "/sponsorship", component: <Sponsorship /> },
  { path: "/promotion", component: <Promotion /> },
  { path: "/partner", component: <Partner /> },
  { path: "/exercise", component: <ExerciseData /> },
  { path: "/view-excercise/:exercise_id", component: <ExerciseDetails /> },
  { path: "/view-diet/:Diet_id", component: <DietDetails /> },
  { path: "/diet", component: <DietPage /> },
  { path: "/time-table", component: <ViewTimetable /> },
  { path: "/self-assessement", component: <SelfAssessment /> },
  { path: "/calculator-page", component: <CalculatorPage /> },
  { path: "/Fitness-Test", component: <FitnessTest /> },
  { path: "/program-trainer", component: <ProgramTrainer /> },
  { path: "/consultancy-trainer", component: <ConsultancyPage /> },
  { path: "/community-network", component: <CommunityNetwork /> },
  { path: "/dt-lock", exact: true, component: <Locked /> },
  { path: "/blog-view", exact: true, component: <ShowBlog /> },
  { path: "/execise-view", exact: true, component: <ExcersiseView /> },
  { path: "/exercise-detail", exact: true, component: <SingleExcercise /> },
  { path: "/diet-view", exact: true, component: <DietView /> },
  // { path: '/appointment', component: <Appointment /> },
  { path: "/view-job", exact: true, component: <ViewJob /> },
  { path: "/view-detail-job", exact: true, component: <JobViewDetail /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
];
export { authProtectedRoutes, publicRoutes };
