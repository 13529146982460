import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './SelfAssessment.css';
import image from '../../../assets/images/selfAssessment/background.png'
import ReactPlayer from 'react-player';
const SelfAssessment = () => {
  return (
    <React.Fragment>

      <div className="__assessment-wrapper">
        <div className="__assessment-image">
          <img
            src={image}
            alt="Movement Assessment"
            className="__assessment-bg-image"
          />
        </div>

        <div className="__gradient-overlay"></div>

        <Container className="__assessment-content">
          <Row>
            <Col className="__assessment-text-container">
              <h1 className="__assessment-title">
                Self-Assessment: How to Check Your Movement Capability
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Container className='px-5'>
          <p className="font-format fs-4">
            You’ve been following a nice new training program for a couple of months and you’re getting better at your workouts. But, it’s just not translating into progress in the things you care about.
          </p>
          <p className="font-format fs-4">
            Sure, you can lift more weight than when you started, but you don’t notice too much carry-over into your sport/activity/hobby/twerking.
          </p>
          <p className="font-format fs-4">
            Fundamentally it’s because if you don’t have a lot of understanding about what is specifically holding you back, a lot of decisions you make about what sort of training to focus on are just guesswork.
          </p>
          <p className="font-format fs-4">
            And when you’re just guessing about decisions in your training, you’re not in control of your progress.
          </p>
          <h3>1-Minute Elements Readiness Assessment</h3>
          <p className="font-format fs-4">
            We’re gonna start at the low-end baseline with a readiness assessment. This video represents the minimum ability required to use our Elements program:
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <div className='d-lg-block d-none'>
                    <ReactPlayer url='https://youtube.com/shorts/YcmTJQS04VE?si=fheDYL7u2yTQNMoo' width='800px' height='400px' />
                </div>
                <div className='d-block d-lg-none'>
                    <ReactPlayer url='https://youtube.com/shorts/YcmTJQS04VE?si=fheDYL7u2yTQNMoo' width='auto' height='300px' />
                </div>
            </div>
          <p className="font-format fs-4">
          This is a quick assessment you can do right now wherever you are. The hardest part is the self-reflection about how you did, what went well, and what needs work.
          </p>
          <h3>Basic Assessment: Get Down and Get Back Up Again</h3>
          <p className="font-format fs-4">
          Put hands on floor, sit down on the ground and sit back. Now shift forward to hands and knees, stand up again. It doesn’t matter if knees are bent/straight, or if you use your hands or not. Just can you do it.
          </p>
          <ul className='fs-4'>
            <li>Do you feel tight/stiff getting up?</li>
            <li>Do you feel like a lack of flexibility is hindering you or is it that your knees and hips could be a bit stronger in the position?</li>
          </ul>
          <p className="font-format fs-4">
          And that’s it! A simple thing but after you spend a bit of time analyzing how you did, you can already see what it reveals about how you can move.
          </p>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default SelfAssessment;
