import PropTypes from 'prop-types';
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";
// Import all middleware
import {Authmiddleware,GuestMiddleware } from "./routes/route";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import '../src/assets/css/custom.css'
// Import scss
import "./assets/scss/theme.scss";
import Preloader from 'components/Common/Preloader';
import Pages404 from 'pages/Error/pages-404';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const isPreloader = useSelector(state=>state.Layout.isPreloader);
  const userData = useSelector(state=>state.Login.authUser);
	const userType = userData?.role?.role_type?.toLowerCase();
  // const userType = JSON.parse(localStorage.getItem('authUser'))?.data?.user?.role.role_type?.toLowerCase();//useSelector(state=>state.Login.authUser?.role.role_type)?.toLowerCase();
  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector( selectLayoutState,(layout) => ({layoutType: layout.layoutType,}));
  const {layoutType} = useSelector(LayoutProperties);
  const Layout = getLayout(layoutType);
  return (  
    <React.Fragment>
      {isPreloader?.status?(<Preloader />):""}
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={<GuestMiddleware><NonAuthLayout>{route.component}</NonAuthLayout></GuestMiddleware>}exact={true} />
        ))}
        {authProtectedRoutes.map((route, idx) =>{
          return (!userType || route.users?.some(role => role?.toLowerCase() == userType))&&(
            <>
              <Route key={idx} path={route.path} element={ <Authmiddleware route={route}><Layout>{route.component}</Layout></Authmiddleware>}  exact={true}/>
              {route.children?.length &&( route.children.map((child,idc)=>(<Route path={child.path} element={<Authmiddleware route={child}><Layout>{child.component}</Layout></Authmiddleware>} key={idc} exact={true}/>)))}
            </>
          )
        })}
        <Route path='*' element={<Pages404 />}></Route>
      </Routes>
    
    </React.Fragment>
  ) 
};
App.propTypes = { layout: PropTypes.any};
export default App;