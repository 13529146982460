import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
export const LoginButton = () => {
  
  return (
    <React.Fragment>
    
        <Link to="/login"
        className="btn btn-success"
          color="success"
          outline
          >

        <i className='bx bx-lock-alt'  />  Log In
        </Link>
      
    </React.Fragment>
  )
}
