import React from 'react';
import { Button, UncontrolledCarousel } from "reactstrap";
import img5 from "assets/images/Events/gymfile.jpg";
import { Link } from 'react-router-dom';

const LeftSlideCompetition = ({ activeTab }) => {
    const getButtonConfig = () => {
        switch (activeTab) {
            case "1":
                return { text: "Create Event", link: "/create-event" };
            case "2":
                return { text: "Create Blog", link: "/create-blog" };
            case "3":
                return { text: "Create Job", link: "/create-job" };
            case "4":
                return { text: "Create Diet Plan", link: "/create-diet-plan" };
            case "5":
                return { text: "Create Exercise", link: "/create-exercise" };
            default:
                return { text: "Create", link: "/" };
        }
    };

    const { text, link } = getButtonConfig();

    // Add custom styles for the image height
    const imageStyle = {
        height: '270px', // Adjust the height as needed
        width:'500px',
        objectFit: 'cover', // Ensures the image covers the entire height without distortion
    };

    return (
        <React.Fragment>
            <div className="mt-5 py-3 px-3">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={img5} alt="Event Image" style={imageStyle} />
                    </div>
                </div>
                <Link to={link}>
                    <Button
                        color="light"
                        block
                        className="mt-0 fs-3 py-4"
                        style={{ borderRadius: '0px' }}
                    >
                        {text}
                    </Button>
                </Link>
            </div>
        </React.Fragment>
    );
};

export default LeftSlideCompetition;
