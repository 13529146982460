import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './Scholarship.css'; // Add custom CSS
import image from '../../../assets/images/mission/promotion.png'
import Header from '../Header';
const Promotion = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">

                <div className="__scholarship-section">
                </div>
                <Container className="__mainpage">
                    <Row className="text-center title-section">
                        <Col>
                            <h1 className="__main-title">Promotion & Branding</h1>
                            <p className="__sub-title">Date | Updated 02 Jul, 2024</p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <img src={image} alt="Sports Scholarships" className="__banner-image" />
                        </Col>
                    </Row>
                    <h3 className='__title-section mt-3'>Introduction</h3>
                    <p className='font-format fs-5 '>

                        "All Work and No Play?" Think Again!

                        We’ve all heard the saying, “All work and no play makes Jack a dull boy”, and it’s true for every generation of students. Often, students focus solely on academics, sidelining their physical development. While good grades are essential for a bright career, don't forget—good health is just as important for enjoying it!

                        But here’s something exciting: excelling in sports doesn’t just benefit your health, it can also open doors to scholarships! In this blog, we’ll introduce you to 25 Sports Scholarships for International Students that can inspire you to balance your studies with sports.

                        With education costs on the rise, these scholarships can be a game-changer for funding your studies abroad. If you're unsure about financing your international education, why not focus on your sports talents to secure one of these opportunities? Or, you can apply for an education loan through WeMakeScholars—we offer top-tier, free services to help you fund your dreams! Visit our website to learn more.


                    </p>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promotion;
