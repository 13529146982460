import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Mission.css'; // Custom CSS file
import Header from '../Header';
import image1 from '../../../assets/images/mission/image1.png'
import image2 from '../../../assets/images/mission/vision.png'
const Mission = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="page-content mt-5">
        <section className="__mission-section">
          <Container>
            {/* Heading */}
            <Row className="text-center mb-4">
              <Col>
                <h2 className="__mission-title">
                  We are on a <span className="__mission-highlight">mission</span>
                </h2>
                <p className="font-format __mission-subtitle">
                  Fit Buds is an award-winning start-up recognised by Start-up India (DPIIT). We are in the process of creating a 'Fit-Culture'. All programs are designed in a way that makes workouts fun so as to build a 'Habit of Exercising' in kids.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="10">
                <div className="__mission-img-wrapper">
                  <img
                    src={image1}
                    alt="Fit Buds Mission"
                    className="img-fluid rounded"

                  />
                </div>
              </Col>
            </Row>
            <Row className="text-center my-4">
              <Col>
                <h2 className="__mission-title">
                  We are on a <span className="__mission-highlight">Our Vision</span>
                </h2>
                <p className="font-format __mission-subtitle">
                  At Fit Buds, our vision is to cultivate a lasting culture of fitness and well-being, starting from a young age. We are committed to creating engaging and enjoyable workout programs that inspire children to develop a lifelong habit of exercising. As an award-winning start-up recognized by Start-up India (DPIIT), we aim to lead the way in promoting a 'Fit-Culture' that empowers kids to stay active, healthy, and happy.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="10">
                <div className="__mission-img-wrapper">
                  <img
                    src={image2}
                    alt="Fit Buds Mission"
                    className="img-fluid rounded"

                  />
                </div>
              </Col>
            </Row>
          </Container>

        </section>
      </div>
    </React.Fragment>
  );
};

export default Mission;
