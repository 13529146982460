import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Col, Container, Row } from 'reactstrap';
import image from '../../../assets/images/Execise/ex2.jpeg';
import { useParams } from 'react-router-dom';
import { changePreloader } from 'store/actions';
import { exercise } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { formatDate } from 'helpers/formatDate';
import './Exercise.css';

export const ExerciseDetails = () => {
    const { exercise_id } = useParams();
    const dispatch = useDispatch();
    const [getExercise, setGetExercise] = useState();

    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }));
        exercise.get(exercise_id)
            .then(res => setGetExercise(res.data.exercise))
            .catch(e => toast.error(e.response ? e.response.data.message : e.message))
            .finally(() => {
                dispatch(changePreloader({ status: false, message: '' }));
            });
    }, [exercise_id]);

    return (
        <React.Fragment>
            <Header />
            <div className="page-content">
                <div className="mx-0 px-0">
                    <div className="__scholarship-section">
                        <h6 className='ms-4'>Follow Us</h6>
                        <div className="__social-icons ms-3 mt-4">
                            <a href={getExercise?.link_facebook} target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-facebook"></i>
                            </a>
                            <br />
                            <a href={getExercise?.link_instaGram} target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-instagram"></i>
                            </a>
                            <br />
                            <a href={getExercise?.link_youTube} target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-youtube"></i>
                            </a>
                            <br />
                        </div>
                    </div>
                    <Container className="__mainpage">
                        <Row className="text-center title-section">
                            <Col>
                                <h1 className="__main-title">{getExercise?.exercise_name.toUpperCase()}</h1>
                                <p className="__sub-title">Created By ({getExercise?.created_by}) | Updated {formatDate(getExercise?.created_at)}</p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <img src={image} alt="Sports Scholarships" className="__banner-image" width={'600px'} />
                            </Col>
                        </Row>
                        <h3 className='__title-section mt-3'>Introduction</h3>
                        <p className='font-format fs-3 '>
                            Exercise plays a vital role in maintaining a healthy lifestyle, especially for students who are balancing academic and extracurricular activities. Engaging in regular physical activity not only helps in physical development but also enhances mental well-being, boosts concentration, and improves overall academic performance.
                        </p>
                        <div className='font-format fs-3'>
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Mechanics </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>{getExercise?.mechanics}</span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Muscle Size </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>{getExercise?.muscle_size}</span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Secondary Muscles </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>
                                                {getExercise && JSON.parse(getExercise.secondary_muscles).join(", ")}
                                            </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Gravity </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>
                                                {getExercise && JSON.parse(getExercise.gravity).join(", ")}
                                            </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Area Of Body Part </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>
                                                {getExercise && JSON.parse(getExercise.area_of_body_part).join(", ")}
                                            </span>
                                        </Col>
                                    </Row>

                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Techniques </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'>
                                                {getExercise && JSON.parse(getExercise.techniques)}
                                            </span>
                                        </Col>
                                    </Row>
                                </li>

                            </ul>
                        </div>
                        <h3 className='__title-section mt-3'>equipments:</h3>
                        <div className='font-format fs-3'>
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Category </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap fw-bold'>Sub Category</span>
                                        </Col>
                                    </Row>
                                </li>
                                {getExercise?.equipments ? (
                                    JSON.parse(getExercise.equipments).map((equipments, idxs) => {
                                        const item = JSON.parse(equipments);
                                        return (
                                            <li key={idxs} className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='text-muted'>{item?.category}</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-muted'>{item?.sub_category}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <p>No muscle group data available</p>
                                )}
                            </ul>
                        </div>
                        <h3 className='__title-section mt-3'>Muscle Group:</h3>
                        <div className='font-format fs-3'>
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Category </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap fw-bold'>Sub Category</span>
                                        </Col>
                                    </Row>
                                </li>
                                {getExercise?.muscle_group ? (
                                    JSON.parse(getExercise.muscle_group).map((muscleItem, idxs) => {
                                        const item = JSON.parse(muscleItem);
                                        return (
                                            <li key={idxs} className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='text-muted'>{item?.category}</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-muted'>{item?.sub_category}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <p>No muscle group data available</p>
                                )}
                            </ul>
                        </div>
                        <h3 className='__title-section mt-3'>Experience Level:</h3>
                        <div className='font-format fs-3'>
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Category </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap fw-bold'>Sub Category</span>
                                        </Col>
                                    </Row>
                                </li>
                                {getExercise?.experience_level ? (
                                    JSON.parse(getExercise.experience_level).map((muscleItem, idxs) => {
                                        const item = JSON.parse(muscleItem);
                                        return (
                                            <li key={idxs} className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='text-muted'>{item?.category}</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-muted'>{item?.sub_category}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <p>No experience level data available</p>
                                )}
                            </ul>
                        </div>
                        <h3 className='__title-section mt-3'>Exercise Type:</h3>
                        <div className='font-format fs-3'>
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'> Category </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap fw-bold'>Sub Category</span>
                                        </Col>
                                    </Row>
                                </li>
                                {getExercise?.exercise_type ? (
                                    JSON.parse(getExercise.exercise_type).map((muscleItem, idxs) => {
                                        const item = JSON.parse(muscleItem);
                                        return (
                                            <li key={idxs} className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='text-muted'>{item?.category}</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-muted'>{item?.sub_category}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <p>No exercise type data available</p>
                                )}
                            </ul>
                        </div>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};
