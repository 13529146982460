import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './Sponsorship.css'; 
import Header from '../Header';
import image from '../../../assets/images/mission/sponsorship.png'
const Sponsorship = () => {
  return (
    <React.Fragment>
    <Header />
    <div className="page-content mt-5">
    
    <Container className="__sponsorship-container">
      <Row className="__title-section">
        <Col md="8">
          <p className="__meta-info">
            MASTER IN SPORT MANAGEMENT <span className="__meta-date"> | OCT 2024 BY JESSICAGEER</span>
          </p>
          <h1 className="__main-title">A Brief Guide to Sponsorship Push Up Board For Men Women Body Building Fitness Training Gym Workout Exercise, Push-up Bar</h1>
        </Col>
        <Col md="4" className="__image-section">
          <img
            src={image}
            alt="Sponsorship in push"
            className="__sponsorship-image"
          />
        </Col>
      </Row>
      <hr />
      <p className='font-format fs-5'>
      Sponsorship is a key revenue source for sports clubs and organizations, helping them thrive at both local and international levels. Brands use sponsorship in sport marketing to reach new audiences and build connections, associating their image with popular teams or athletes.

By sponsoring, brands "borrow" the spotlight of sports entities and gain visibility through various channels: logos on gear, venue ads, event suppliers, media rights, or even stadium naming rights. It's a powerful tool to boost brand exposure and customer engagement.
      </p>

    </Container>
    </div>
    </React.Fragment>
  );
};

export default Sponsorship;
