import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import image from '../../../assets/images/fitnessTest/consultancy.png'
import consultancy2 from '../../../assets/images/fitnessTest/consultancy2.png'
import './ConsultancyPage.css'
const ConsultancyPage = () => {
  return (
    <React.Fragment>

      <div className="__assessment-wrapper">
        <div className="__assessment-image">
          <img
            src={image}
            alt="Movement Assessment"
            className="__assessment-bg-image"
          />
        </div>

        <div className="__gradient-overlay"></div>
        <Container className="__assessment-content">
          <Row>
            <Col className="__assessment-text-container">
              <h1 className="__assessment-title">
              Consulting
              </h1>
             <h4>With over 14 years of fitness industry experience, we’ve created dozens of industry experts.</h4>
              
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Container className='px-5'>
          <h1 className='mt-3 text-center text-info px-5'>
          Leaders in gym industry consulting
          </h1>
          <p className="font-format fs-4 text-center px-5">
          With decades of fitness industry experience, Gymers Consultant is a trusted expert for all your fitness needs. We have successfully managed countless gyms across India and we are here to help. To manage both operators of the single gym and multi-club operations, we have experience in providing excellent customer service, financial analysis, developing marketing strategies, creating fitness programs, and designing organizational structures & business processes. We also help you determine the right fitness services to ensure that your gym members make the most of your fitness facility.
          </p>
          
        </Container>
        

  
      <Container className="__consulting-services">
        <Row>
          <Col md="6" className="__services-image-container">
            <img
              src={consultancy2} 
              alt="Team Discussion"
              className="__services-image"
            />
          </Col>
          <Col md="6" className="__services-content">
            <h2 className="__services-title">We help you with</h2>
            <ul className="__services-list">
              <li>📏 Choose the appropriate sizes of your gym.</li>
              <li>📍 Location Study</li>
              <li>📐 Design & Setup</li>
              <li>🛠️ Amenities selection</li>
              <li>🏋️‍♀️ Equipment Selection</li>
              <li>👨‍🏫 Hire Trained Staffs</li>
              <li>🏗️ Gym pre-opening preparation</li>
              <li>📊 Investment & Forecasting</li>
              <li>📈 Gym business plan</li>
              <li>🤝 Mediation</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="__consultation-cta">
        <h3 className="__cta-title">Schedule a free consultation</h3>
        <p className="__cta-description">
          Reach out to learn how we can help your specific situation.
        </p>
        <Button className="__cta-button"  href="tel:+917678597421">
          +91-7678597421
        </Button>
      </div>
      </div>
    </React.Fragment>
  );
};

export default ConsultancyPage;
