import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody, Nav, NavItem, NavLink, Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import './slider_custom.css';
import img1 from "assets/images/slider/img1.jpg";
import img2 from "assets/images/slider/img2.jpg";
import img3 from "assets/images/slider/img3.jpg";
import img4 from "assets/images/slider/img4.jpg";
import img5 from "assets/images/slider/img5.jpg";

const SocialMediaSlider = () => {
  const [activeTab, setActiveTab] = useState('KNOWLEDGE');
  const [activeIndex, setActiveIndex] = useState(0);

  const data = {
    KNOWLEDGE: [
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 1',
        backgroundImage: `url(${img1})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 2',
        backgroundImage: `url(${img2})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 3',
        backgroundImage: `url(${img3})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 4',
        backgroundImage: `url(${img4})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 4',
        backgroundImage: `url(${img4})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 4',
        backgroundImage: `url(${img4})`, 
      },
      {
        platform: 'INSTAGRAM',
        heading: 'Knowledge Post 4',
        backgroundImage: `url(${img4})`, 
      },
    ],
    STORIES: [
      {
        platform: 'YOUTUBE',
        heading: 'Story Post 1',
        backgroundImage:`url(${img5})`, 
      },
      {
        platform: 'YOUTUBE',
        heading: 'Story Post 2',
        backgroundImage: `url(${img1})`, 
      },
      {
        platform: 'YOUTUBE',
        heading: 'Story Post 3',
        backgroundImage: `url(${img2})`, 
      },
      {
        platform: 'YOUTUBE',
        heading: 'Story Post 4',
        backgroundImage: `url(${img1})`, 
      },
    ],
    NETWORKING: [
      {
        platform: 'OTHERS',
        heading: 'Networking Post 1',
        backgroundImage: `url(${img5})`, 
      },
      {
        platform: 'OTHERS',
        heading: 'Networking Post 2',
        backgroundImage: `url(${img3})`, 
      },
      {
        platform: 'OTHERS',
        heading: 'Networking Post 3',
        backgroundImage: `url(${img4})`, 
      },
      {
        platform: 'OTHERS',
        heading: 'Networking Post 4',
        backgroundImage: `url(${img2})`, 
      },
    ],
  };

  const next = () => {
    setActiveIndex((prevIndex) => (prevIndex === Math.ceil(data[activeTab].length / 4) - 1 ? 0 : prevIndex + 1));
  };

  const previous = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? Math.ceil(data[activeTab].length / 4) - 1 : prevIndex - 1));
  };
  

  const slides = () => {
    const itemsPerSlide = 6;
    const totalSlides = Math.ceil(data[activeTab].length / itemsPerSlide);
    const slideItems = [];

    for (let i = 0; i < totalSlides; i++) {
      const items = data[activeTab].slice(i * itemsPerSlide, i * itemsPerSlide + itemsPerSlide);
      slideItems.push(
        <CarouselItem key={i}>
          <Row className="justify-content-center mx-3">
            {items.map((item, index) => (
              <Col md="3" lg="2" className="mb-4" key={index}>
                <Card className="media-card" style={{ backgroundImage: item.backgroundImage,height:'350px' }}>
                  <CardBody className="text-center">
                    <h5 className="media-platform fs-4">{item.platform}</h5>
                    <h6 className="text-white py-3 fs-5">{item.heading}</h6>
                  </CardBody>
                    <div className='pt-5'>
                    <Button color="light" block className="mt-5">VIEW MORE</Button>
                    </div>
                </Card>
              </Col>
            ))}
          </Row>
        </CarouselItem>
      );
    }
    return slideItems;
  };

  return (
    <section className="social-media-section py-4 bg-light">
        <h2 className="section-title text-center mb-4">SOCIAL MEDIA</h2>

        <Nav className="tabs justify-content-center mb-4">
          {['KNOWLEDGE', 'STORIES', 'NETWORKING'].map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={activeTab === tab ? 'tab active-tab' : 'tab'}
                onClick={() => {
                  setActiveTab(tab);
                  setActiveIndex(0); 
                }}
                style={{ cursor: 'pointer' }}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides()}
          <CarouselControl direction="prev" className='text-dark' directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" className='text-dark' directionText="Next" onClickHandler={next} />
        </Carousel>
    </section>
  );
};

export default SocialMediaSlider;
