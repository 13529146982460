import React from 'react'
import { Container, Row } from 'reactstrap'
import './Partner.css'
export const Partner = () => {
    return (
        <React.Fragment>
            <div className='page-content mt-5'>
                <Container className='mt-5'>
                    <Row className="mt-5">
                        <h1>Partner with Us for Sports Collaborations</h1>
                        <p className='font-format fs-3'>
                            Are you looking to grow your brand in the fitness or sports industry? Partner with us and tap into a community passionate about health, fitness, and sports. By aligning your brand with our platform, you’ll get access to an engaged audience eager for innovative products and services. Together, we can create tailored partnerships that maximize value for both parties, boosting visibility, and fostering long-term growth.
                        </p>
                        <h1>Barter Deals to Elevate Your Sports Brand</h1>
                        <p className='font-format fs-3'>
                            Want to expand your reach without immediate financial investment? Our barter opportunities allow you to trade products, services, or sponsorships in exchange for brand promotion through our sports and gym channels. Whether it's providing gym equipment, apparel, or sponsoring events, we can offer you exposure, marketing, and advertising in return. It’s a win-win that strengthens relationships and builds brand awareness, all while supporting healthy, active lifestyles.
                        </p>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
