import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import image from '../../../assets/images/fitnessTest/trainer.png'
import programimage from '../../../assets/images/fitnessTest/program.png'
import ReactPlayer from 'react-player';
const ProgramTrainer = () => {
  return (
    <React.Fragment>

      <div className="__assessment-wrapper">
        <div className="__assessment-image">
          <img
            src={image}
            alt="Movement Assessment"
            className="__assessment-bg-image"
          />
        </div>

        <div className="__gradient-overlay"></div>
        <Container className="__assessment-content">
          <Row>
            <Col className="__assessment-text-container">
              <h1 className="__assessment-title">
              Online Programs:
              Structured Training for Physical Autonomy
              </h1>
              
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Container className='px-5'>
          <h1 className='mt-3'>
          Take all the guesswork out of building a strong, agile body that never lets you down…
          </h1>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <img
            src={programimage}
            alt="Movement Assessment"
            className="__assessment-bg-image"
          />
            </div>
            <p className="font-format fs-4">
            Our online training programs walk you step-by-step through a proven process that gets you stronger, less stiff, and more agile with every session.
          </p>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProgramTrainer;
