import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './Scholarship.css'; // Add custom CSS
import image from '../../../assets/images/mission/scholarship.png'
import Header from '../Header';
const Scholarship = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">
            
                <div className="__scholarship-section">
                </div>
                    <Container className="__mainpage">
                        <Row className="text-center title-section">
                            <Col>
                                <h1 className="__main-title">25 Sports Scholarships for International Students</h1>
                                <p className="__sub-title">Admissions | Updated 02 Jul, 2024</p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <img src={image} alt="Sports Scholarships" className="__banner-image" />
                            </Col>
                        </Row>
                        <h3 className='__title-section mt-3'>Introduction</h3>
                        <p className='font-format fs-5 '>
                            
                            All work and no play makes Jack a dull boy", this very famous ancient proverb holds for every generation of students. Manier times students get so indulged in studies and their academics and take their physical development for granted. It may be fine according to some, because after all good academic grades matter a lot for a bright career, but do not forget that a bright career also could not be enjoyed without good health. So, students need to keep a balance between their studies and sports activities. But do you know, being good in sports can fetch you scholarships as well?

                            In this blog, we are going to tell you about the 25 sports scholarships for international students that will motivate you to keep a balance between your studies and sports activities. As the cost of education is increasing consistently, if you are unsure about your abroad education funding sources you can start working on your sports activities and thrive to get one or more of the below-mentioned scholarships or you can apply for an education loan through WeMakeScholars, our services are best in class and free of cost. To know more visit our website.
                        </p>
                    </Container>
            </div>
        </React.Fragment>
    );
};

export default Scholarship;
