import React from 'react';
import { Container, Row, Col, Button, CardBody, Card } from 'reactstrap';
import image from '../../../assets/images/fitnessTest/background.png'
const CommunityNetwork = () => {
    return (
        <React.Fragment>
            <div className="page-content mt-5">

                <div className="__scholarship-section">
                    <div className="__assessment-wrapper">
                        <div className="__assessment-image">
                            <img
                                src={image}
                                alt="Movement Assessment"
                                className="__assessment-bg-image"
                            />
                        </div>

                        <div className="__gradient-overlay"></div>

                        <Container className="__assessment-content">
                            <Row>
                                <Col className="__assessment-text-container">
                                    <h1 className="__assessment-title">
                                        Self-Assessment: How to Check Your Movement Capability
                                    </h1>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Card>
                                        <CardBody>
                                            <h1 className='text-center'>Fittestwarrior Fitness Centers</h1>
                                            <p className='font-format fs-4 '>
                                                Community Health Network’s Fittestwarrior fitness centers are designed to improve our employees' health and fitness, simplify their lives with convenient facilities, and be a part of their exceptional employee experience. Fitness classes are also open to non-Fittestwarrior members.
                                                Fittestwarrior North, East and South fitness centers are open, and we are back to 24/7 hours of operation. To help keep you safe, we provide individual spray bottles for members to use while they are working out. These bottles will be refilled and disinfected when they are returned.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container className="__mainpage">
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Card>
                                <CardBody>
                                    <h1 className='text-center'>Fittestwarrior Fitness Centers</h1>
                                    <p className='font-format fs-4 '>
                                        Community Health Network’s Fittestwarrior fitness centers are designed to improve our employees' health and fitness, simplify their lives with convenient facilities, and be a part of their exceptional employee experience. Fitness classes are also open to non-Fittestwarrior members.
                                        Fittestwarrior North, East and South fitness centers are open, and we are back to 24/7 hours of operation. To help keep you safe, we provide individual spray bottles for members to use while they are working out. These bottles will be refilled and disinfected when they are returned.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h3 className='__title-section mt-3'>Introduction</h3>
                    <p className='font-format fs-4 '>
                        Fitness tests help gauge where you are currently in your fitness journey, ideal for starting a new workout routine and tracking your progress as you continue advancing in your health journey.

                        Sports performance labs offer elaborate and sometimes costly fitness tests. However, most people interested in assessing their fitness baseline can identify and track their levels with a few simple at-home tests. Always talk with your doctor before beginning or increasing physical activity.
                    </p>

                    <h3 className='__title-section mt-3'>Fitness Tests</h3>
                    <p className='font-format fs-4 '>
                        Use a fitness test to determine your current fitness level before beginning a new fitness program. You'll need a few items to perform each of these fitness tests. The tools you'll want to have handy:
                        <ul className='fs-4'>
                            <li><b>Core strength and stability test: </b>Stopwatch, exercise mat (optional)</li>
                            <li><b>Push-up test: </b>Stopwatch, exercise mat (optional)</li>
                            <li><b>12-minute run test:  </b>someplace to run</li>
                        </ul>
                        It may also be helpful to have a helper count your push-up repetitions, keep time during the core test, or calculate the distance you run. Write your fitness test results in your journal or record them in your favorite fitness app to keep track of your progress.
                    </p>
                    <h3 className='__title-section mt-3'>Core Strength and Stability Fitness Test</h3>
                    <p className='font-format fs-4 '>
                        This test assesses core strength and stability, as its name suggests, and also upper body strength. It is sometimes referred to as the plank fitness test because it uses the plank position to assess strength.
                    </p>
                    <p className='font-format fs-4 '>
                        An advantage of this test is its simplicity, as well as how it engages multiple muscles to help support the core. This test can give you a good read on your current core strength and can be used to follow your progress over time.
                    </p>
                    <p className='font-format fs-4 '>
                        People who can do 100 crunches are sometimes surprised when they can't fully complete this test. Try it and see for yourself. If you can't finish the test, however, don't fret. It simply means that you need to work at improving your core strength.
                    </p>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CommunityNetwork;
