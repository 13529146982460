import React, { useState } from 'react';
import Header from '../Header';
import { Card, CardBody, Row, Col, Carousel, CarouselIndicators, CarouselControl, CarouselItem } from 'reactstrap';
import './AboutUsPage.css';
import image1 from '../../../assets/images/slider/img1.jpg';
import image2 from '../../../assets/images/slider/img2.jpg';
import image3 from '../../../assets/images/slider/img3.jpg';
import img1 from '../../../assets/images/about/img1.png';
import img2 from '../../../assets/images/about/img2.png';
import img3 from '../../../assets/images/about/img3.png';
import img4 from '../../../assets/images/about/img4.png';
import img5 from '../../../assets/images/about/img5.png';
import img6 from '../../../assets/images/about/img6.png';
import img7 from '../../../assets/images/about/img7.png';
import img8 from '../../../assets/images/about/img8.png';
import img9 from '../../../assets/images/about/img9.png';
import img10 from '../../../assets/images/about/img10.png';
import img11 from '../../../assets/images/about/img11.png';
import img12 from '../../../assets/images/about/img12.png';
import img13 from '../../../assets/images/about/img13.png';
import img14 from '../../../assets/images/about/img14.png';
import SlideMain from '../CarouselTypes/SlideMain';


export const AboutUsPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const aboutUsData = [
        { image: image1, effect: "fade-effect", },
        { image: image2, effect: "zoom-effect", },
        { image: image3, effect: "slide-effect", }
    ];
    const whoWeAreData = [
        { image: img2, effect: "zoom-effect", },
        { image: img1, effect: "fade-effect", },
        { image: img3, effect: "slide-effect", }
    ];
    const participatorData = [
        { image: img4, effect: "fade-effect", },
        { image: img6, effect: "slide-effect", },
        { image: img5, effect: "zoom-effect", },
    ];
    const professionalData = [
        { image: img6, effect: "zoom-effect", },
        { image: img5, effect: "fade-effect", },
        { image: img7, effect: "slide-effect", }
    ];
    const clientData = [
        { image: img8, effect: "fade-effect", },
        { image: img10, effect: "slide-effect", },
        { image: img9, effect: "zoom-effect", },
    ];
    const visitorData = [
        { image: img12, effect: "zoom-effect", },
        { image: img11, effect: "fade-effect", },
        { image: img13, effect: "slide-effect", }
    ];
    const communityData = [
        { image: img14, effect: "fade-effect", },
        { image: img1, effect: "slide-effect", },
        { image: img4, effect: "zoom-effect", },
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === aboutUsData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? aboutUsData.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = aboutUsData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides2 = whoWeAreData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides3 = participatorData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides4 = professionalData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides5 = visitorData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides6 = communityData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides7 = clientData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });

    return (
        <React.Fragment>
            <Header />
            <SlideMain />
            <div className="page-content " style={{ background: '#f5f5f5',overflow:'hidden' }}>
                <h2 className='text-center' style={{ color: '#805e18' }}>Know us Information</h2>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card py-5' >
                                    <h2 className=''>Who We Are</h2>
                                    <p className='font-format fs-2 '>
                                        "Who We Are" section should emphasize the company's connection to sports, its values, and its mission in the sports industry.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides2}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >
                        <div className=' ps-4 py-5'>
                            <h2 className=''>For Community</h2>
                            <p className='font-format fs-2'>
                                We are a passionate, community-driven organization dedicated to creating a safe, inclusive, and supportive environment where individuals of all ages and backgrounds can thrive. Our mission is to bring people together, foster positive change, and empower each member of the community to reach their full potential.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>
                                    <h2 className=''>For Investors</h2>
                                    <p className='font-format fs-2 '>
                                        We are committed to driving sustainable growth and creating long-term value for our investors through innovation, transparency, and strategic leadership.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides3}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides4}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >

                        <div className='ps-4 py-5'>
                            <h2 className=''>For Participator</h2>
                            <p className='font-format fs-2 '>
                                We welcome all participants to join us in creating meaningful experiences, fostering collaboration, and making a lasting impact together.
                            </p>
                        </div>

                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>
                                    <h2 className=''>For proffessional</h2>
                                    <p className='font-format fs-2'>
                                        We are dedicated to maintaining the highest standards of excellence and integrity, delivering expert solutions with professionalism and precision.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel  my-5" fade={true}>
                            {slides5}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides6}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >
                        <div className=' ps-4 py-5'>
                            <h2 className=''>For Visitor</h2>
                            <p className='font-format fs-2 '>
                                We warmly welcome visitors and invite you to explore, engage, and discover all that we have to offer.</p>
                        </div>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>

                                    <h2 className=''>For Client</h2>
                                    <p className='font-format fs-2 '>
                                        We are here to provide personalized, high-quality solutions that meet your goals, ensuring a seamless and successful partnership.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides7}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};
export default AboutUsPage;
