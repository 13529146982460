import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Col,
  Row
} from 'reactstrap';
import './slider_custom.css'; 
import img1 from "assets/images/slider/img1.jpg";
import img2 from "assets/images/slider/img2.jpg";
import img3 from "assets/images/slider/img3.jpg";
import img4 from "assets/images/slider/img4.jpg";
import img5 from "assets/images/slider/img5.jpg";
import { Link } from 'react-router-dom';

const AboutUsSlider = () => {
  const cardData = [
    { title: "Who We Are", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", image: img1 },
    { title: "For Community", content: "Lorem ipsum dolor sit amet.", image: img2 },
    { title: "For Investors", content: "Lorem ipsum dolor sit, consectetur adipiscing elit, sed do." , image: img3 },
    { title: "For Professionals", content: "Lorem ipsum dolor sit amet, consectetur.", image: img4 },
    { title: "For Participants", content: "Lorem ipsum dolor sit, amet adipiscing elit." , image: img5 },
    { title: "For Visitors", content: "Lorem ipsum dolor sit, amet adipiscing elit." , image: img5 },
  ];

  const cardChunks = [];
  for (let i = 0; i < cardData.length; i += 5) {
    cardChunks.push(cardData.slice(i, i + 5));
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === cardChunks.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? cardChunks.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = cardChunks.map((chunk, idx) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={idx}
    >
      <div className="card-group" style={styles.cardGroup}>
        {chunk.map((card, index) => (
          <Col lg={2} key={index} className='mx-auto'>
          <Card  style={styles.card} >
            <div className="card-background" style={{ backgroundImage: `url(${card.image})` }}>
              <CardBody style={styles.cardBody}>
                <div className="text-container">
                  <CardTitle tag="h5" className='fs-2 pb-3' style={styles.cardTitle}><strong><em>{card.title}</em></strong></CardTitle>
                  <CardText className='text-truncate py-3' style={styles.cardText}>{card.content}</CardText>
                </div>
                <Link to={"/aboutus"} className="mt-auto btn btn-light btn-block">Read More</Link>
              </CardBody>
            </div>
          </Card>
          </Col>
        ))}
      </div>
    </CarouselItem>
  ));

  return (
    <div className="about-us-section" style={styles.section}>
      <h2 style={styles.header}>About Us</h2>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}style={styles.carousel}>
        <CarouselIndicators items={cardChunks} activeIndex={activeIndex}onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous"onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
      </Carousel>
    </div>
  );
};

const styles = {
  section: {
    backgroundColor: '#2c2f33',
    color: '#fff',
    padding: '40px',
    textAlign: 'center',
  },
  header: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  carousel: {
    maxWidth: '100%',
  },
  cardGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  card: {
    backgroundColor: 'transparent', 
    border: 'none', 
    borderRadius: '8px',
    padding: '0', 
    width: '250px',
    margin: '10px',
    height: '300px',
    position: 'relative',
    overflow: 'hidden', 
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
    zIndex: 2,
  },
  cardTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: 'white', 
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)', 
  },
  cardText: {
    fontSize: '1rem',
    marginBottom: '15px',
    color: 'white',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
  },


};

export default AboutUsSlider;
